<template>
  <div>
    <div style="display: flex">
      <div :style="{ height: height }" class="wrapper">
        <el-form
          :inline="true"
          :model="formInline"
          label-width="100px"
          class="demo-form-inline"
          style="overflow: hidden"
          id="middle"
        >
          <template v-for="item in formItemList">
            <el-form-item :key="item.attr" :label="item.label">
              <!-- <div style="color: #ccc" v-if="item.type === 'text'">{{
                item.text
              }}</div> -->
              <el-select
                disabled
                v-if="item.type === 'text'"
                :value="item.text"
              ></el-select>
              <el-input
                :placeholder="item.placeholder"
                v-model="formInline[item.attr]"
                v-if="item.type === 'input'"
                style="width: 222px"
                clearable
              >
              </el-input>
              <el-select
                v-model="formInline['100']"
                placeholder="请选择材质成色"
                filterable
                v-if="item.type === '100'"
                clearable
              >
                <template v-for="item in materialList">
                  <el-option
                    :label="item.attr_value"
                    :value="item.attr_value_id"
                    :key="item.attr_value_id"
                  >
                  </el-option>
                </template>
              </el-select>
              <el-select
                v-model="formInline['101']"
                placeholder="请选择款式"
                filterable
                v-if="item.type === '101'"
                clearable
              >
                <template v-for="item in patternList">
                  <el-option
                    :label="item.attr_value"
                    :value="item.attr_value_id"
                    :key="item.attr_value_id"
                  >
                  </el-option>
                </template>
              </el-select>
              <el-select
                v-model="formInline['hierarchy_id']"
                placeholder="请选择所属门店"
                filterable
                v-if="item.type === 'hierarchy_id'"
                clearable
              >
                <template v-for="item in merchantList">
                  <el-option
                    :label="item.hierarchy_name"
                    :value="item.hierarchy_id"
                    :key="item.hierarchy_id"
                  >
                  </el-option>
                </template>
              </el-select>
              <el-select
                v-model="formInline['warehouse_id']"
                placeholder="请选择仓库"
                filterable
                v-if="item.type === 'warehouse_id'"
                clearable
              >
                <template v-for="item in warehouseList">
                  <el-option
                    :label="item.warehouse_name"
                    :value="item.warehouse_id"
                    :key="item.warehouse_id"
                  >
                  </el-option>
                </template>
              </el-select>
              <div v-if="item.type === 'sale_price'" style="display: flex">
                <el-input
                  :placeholder="'请输入标签价范围'"
                  v-model="formInline.sale_price[0]"
                  type="number"
                  style="width: 222px"
                  clearable
                >
                </el-input>
                <div style="margin: 0 48px">至</div>
                <el-input
                  :placeholder="'请输入标签价范围'"
                  v-model="formInline.sale_price[1]"
                  type="number"
                  style="width: 222px"
                  clearable
                >
                </el-input>
              </div>
              <div v-if="item.type === '104'" style="display: flex">
                <el-input
                  :placeholder="'请输入净金重范围'"
                  v-model="formInline['104'][0]"
                  style="width: 222px"
                  type="number"
                  clearable
                >
                </el-input>
                <div style="margin: 0 48px">至</div>
                <el-input
                  :placeholder="'请输入净金重范围'"
                  v-model="formInline['104'][1]"
                  style="width: 222px"
                  type="number"
                  clearable
                >
                </el-input>
              </div>
              <el-select
                v-model="formInline['103']"
                placeholder="请选择品牌"
                filterable
                v-if="item.type === '103'"
                clearable
              >
                <template v-for="item in brandList">
                  <el-option
                    :label="item.band_name"
                    :value="item.band_id"
                    :key="item.band_id"
                  >
                  </el-option>
                </template>
              </el-select>
              <el-date-picker
                v-if="item.type === 'entry_time'"
                v-model="formInline.entry_time"
                type="daterange"
                align="right"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
              <SelectTree
                :disabled="item.disabled"
                v-if="item.type === 'treeSelect'"
                :props="props"
                :value="valueId"
                :options="treeData"
                :options2="treeData2"
                :clearable="isClearable"
                :accordion="isAccordion"
                label1="商品分类"
                label2="旧料分类"
                @getValue="getValue($event, item.attr)"
              />
            </el-form-item>
          </template>
          <!-- <el-form-item>
            <el-button @click="handleReset">重置</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">搜索</el-button>
          </el-form-item> -->
        </el-form>
      </div>
      <el-button
        @click="handleHeight"
        style="
          width: 40px;
          height: 40px;
          line-height: 36px;
          text-align: center;
          padding: 0;
        "
      >
        <i class="el-icon-caret-bottom" v-if="height === '55px'"></i>
        <i class="el-icon-caret-top" v-else></i>
      </el-button>
      <el-button type="primary" @click="searchData()" style="height: 40px"
        >搜索</el-button
      >
      <el-button @click="handleReset" style="height: 40px">重置</el-button>
    </div>

    <el-table
      ref="singleTable"
      :data="shopList"
      :row-key="(row) => row.goods_id"
      stripe
      highlight-current-row
      :height="500"
      v-loading="loading"
    >
      <el-table-column
        type="index"
        label="序号"
        align="center"
        width="50"
      ></el-table-column>
      <el-table-column prop="warning_state" label="滞销预警" align="center">
        <template slot-scope="scope">
          <div>
            <el-tooltip
              class="item"
              effect="dark"
              content="警报等级依次为：绿<黄<橙<红"
              placement="top-start"
              v-if="
                scope.row.warning_state !== 10 &&
                scope.row.warning_state !== 20 &&
                scope.row.warning_state !== 30
              "
            >
              <div
                style="
                  background-color: #21bc36;
                  width: 12px;
                  height: 12px;
                  border-radius: 50%;
                  margin: 0 auto;
                "
              ></div>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="警报等级依次为：绿<黄<橙<红"
              placement="top-start"
              v-else-if="scope.row.warning_state === 10"
            >
              <div
                style="
                  background-color: #ffdf04;
                  width: 12px;
                  height: 12px;
                  border-radius: 50%;
                  margin: 0 auto;
                "
              ></div>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="警报等级依次为：绿<黄<橙<红"
              placement="top-start"
              v-else-if="scope.row.warning_state === 20"
            >
              <div
                style="
                  background-color: #ff9000;
                  width: 12px;
                  height: 12px;
                  border-radius: 50%;
                  margin: 0 auto;
                "
              ></div>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="警报等级依次为：绿<黄<橙<红"
              placement="top-start"
              v-else-if="scope.row.warning_state === 30"
            >
              <div
                style="
                  background-color: #f4390d;
                  width: 12px;
                  height: 12px;
                  border-radius: 50%;
                  margin: 0 auto;
                "
              ></div>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="goods_pic" label="货品图片" align="center">
        <template slot-scope="scope">
          <el-image
            style="width: 40px; height: 40px"
            :src="scope.row.goods_pic"
            :preview-src-list="[scope.row.goods_pic]"
          >
            <div slot="error" class="image-slot">
              <img :src="noPic" style="width: 40px; height: 40px" />
            </div>
          </el-image>
        </template>
      </el-table-column>
      <el-table-column
        prop="entry_number"
        label="入库单号"
        align="center"
        width="210"
      ></el-table-column>
      <el-table-column
        prop="goods_number"
        label="货号"
        align="center"
        width="200"
      >
        <template slot-scope="scope">
          {{ scope.row.goods_number || "--" }}
        </template>
      </el-table-column>
      <el-table-column prop="goods_style_number" label="款号" align="center">
        <template slot-scope="scope">
          {{ scope.row.goods_style_number || "--" }}
        </template>
      </el-table-column>
      <el-table-column
        prop="goods_name"
        align="center"
        label="品名"
        width="200"
      >
        <template slot-scope="scope">
          <div
            style="display: flex; align-items: center; justify-content: center"
          >
            {{ scope.row.goods_name || "--" }}
            <template v-for="item in scope.row.goods_tag_pic">
              <img
                :src="item"
                :key="item"
                style="margin-left: 5px; height: 15px"
              />
            </template>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="goods_number_type" align="center" label="货品类型">
        <template slot-scope="scope">
          {{
            scope.row.goods_number_type === 10
              ? "一码一货"
              : scope.row.goods_number_type === 20
              ? "一码多货"
              : ""
          }}
        </template>
      </el-table-column>
      <el-table-column
        prop="usable_count"
        label="可操作数量"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="hierarchy_name"
        align="center"
        label="所属门店"
        width="150"
      ></el-table-column>
      <el-table-column
        prop="warehouse_name"
        align="center"
        label="仓库"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="stock"
        align="center"
        label="库存"
      ></el-table-column>
      <el-table-column prop="sale_price" align="center" label="标签价">
        <template slot-scope="scope">
          {{ scope.row.sale_price || "--" }}
        </template>
      </el-table-column>
      <el-table-column prop="184" align="center" label="销售工费">
        <template slot-scope="scope">
          {{ scope.row["184"] || "--" }}
        </template>
      </el-table-column>
      <el-table-column prop="185" align="center" label="销售工费类型">
        <template slot-scope="scope">
          {{ scope.row["185"] || "--" }}
        </template>
      </el-table-column>
      <el-table-column prop="101" align="center" label="款式">
        <template slot-scope="scope">
          {{ scope.row["101"] || "--" }}
        </template>
      </el-table-column>
      <el-table-column prop="100" align="center" label="材质成色">
        <template slot-scope="scope">
          {{ scope.row["100"] || "--" }}
        </template>
      </el-table-column>
      <el-table-column prop="104" align="center" label="净金重">
        <template slot-scope="scope">
          {{ scope.row["104"] || "--" }}
        </template>
      </el-table-column>
      <el-table-column prop="130" align="center" label="主石重">
        <template slot-scope="scope">
          {{ scope.row["130"] || "--" }}
        </template>
      </el-table-column>
      <el-table-column prop="133" align="center" label="辅石1重量">
        <template slot-scope="scope">
          {{ scope.row["133"] || "--" }}
        </template>
      </el-table-column>
      <el-table-column prop="112" align="center" label="证书号">
        <template slot-scope="scope">
          {{ scope.row["112"] || "--" }}
        </template>
      </el-table-column>
      <el-table-column
        prop="entry_time"
        align="center"
        label="入库时间"
        width="200"
      >
        <template slot-scope="scope">
          {{ scope.row["entry_time"] || "--" }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="action"
        label="操作"
        width="100"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            :disabled="handleDisable(scope.row) === true ? true : false"
            type="text"
            @click="handleCurrentShop(scope.row)"
            >选择此商品</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagina">
      <el-pagination
        background
        layout="total, prev, pager, next, jumper"
        :total="total"
        :page-size="50"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import {
  getList as goodsReq,
  getAllGoodsListNew,
} from "@/api/goods/goodsStock/goodsDetailedlist/index";
import { getWarehouseList } from "@/api/goods/goodsStock/goodsInventory/index.js";
import { getMerchantList } from "@/api/goods/goodsWarehousing/warehousing.js";
import { getGoodsTypeList } from "@/api/goods/goodsWarehousing/warehousing";

import { post } from "@/utils/request";
const noPic = require("@/assets/images/nopic.png");

export default {
  data() {
    return {
      noPic: noPic,
      formInline: {
        104: [],
        sale_price: [],
        page: 1,
        page_num: this.$store.state.pageSizes[0],
      },
      shopList: [],
      total: 0,
      height: "55px",
      loading: false,
      brandList: [],
      materialList: [],
      warehouseList: [],
      patternList: [],
      merchantList: [],
      formItemList: [
        {
          label: "品类",
          placeholder: "请选择",
          attr: "goods_type_id",
          type: "treeSelect",
        },
        {
          label: "货号",
          placeholder: "请填写货号",
          attr: "goods_number",
          type: "input",
        },
        {
          label: "入库单号",
          placeholder: "请填写入库单号",
          attr: "entry_number",
          type: "input",
        },
        {
          label: "款号",
          placeholder: "请填写入款号",
          attr: "goods_style_number",
          type: "input",
        },
        {
          label: "品名",
          placeholder: "请填写入品名",
          attr: "goods_name",
          type: "input",
        },
        { type: "hierarchy_id", label: "所属门店" },
        { type: "warehouse_id", label: "仓库" },
        { type: "101", label: "款式" },
        { type: "100", label: "材质成色" },
        { type: "103", label: "品牌" },
        { type: "sale_price", label: "标签价" },
        { type: "104", label: "净金重" },
        { type: "entry_time", label: "入库时间" },
      ],
      treeData: [],
      treeData2: [],
      warehouseList: [],
      isClearable: true, // 可清空（可选）
      isAccordion: true, // 可收起（可选）
      valueId: 0,
      props: {
        value: "goods_type_id",
        label: "title",
        children: "child",
      },
    };
  },
  props: {
    prizeList: Array,
    type: Number,
    hierarcyhList: Array,
  },
  watch: {
    formInline: {
      handler(val) {
        this.valueId = val.goods_type_id;
      },
      immediate: true,
    },
  },
  created() {
    this.getBandList();
    this.getMaterialList();
    this.getWarehouseList();
    this.getPatternList();
    this.getMerchantList();
    this.handleGetGoodsTypeList();
    this.handleGetGoodsTypeList2();
    this.getGoods();
    // 赋值
    const formInline = { ...this.formInline };
    this.formItemList.forEach((item) => {
      if (item.value) {
        formInline[item.attr] = item.value;
      }
    });
    this.formInline = formInline;
  },
  methods: {
    handleDisable(row) {
      var disable = false;
      this.prizeList.forEach((item) => {
        if (item.goods_id && item.goods_id === row.goods_id) {
          disable = true;
        }
      });
      if (row.usable_count === 0) {
        disable = true;
      }
      return disable;
    },
    // 取值
    getValue(value, attr) {
      const formInline = { ...this.formInline };
      formInline[attr] = value;
      this.formInline = formInline;
    },
    handleGetGoodsTypeList() {
      getGoodsTypeList({
        type: [10],
        isHaveAll: 0,
      }).then((res) => {
        if (res.code === 1) {
          this.treeData = res.data;
          this.typeList = this.handleArr(res.data);
        }
      });
    },
    handleArr(arr) {
      const newArr = [];
      function poling(arr) {
        arr.forEach((item) => {
          newArr.push(item);
          if (item.child) {
            poling(item.child);
          }
        });
      }
      poling(arr);
      return newArr;
    },
    handleGetGoodsTypeList2() {
      getGoodsTypeList({
        type: [20],
        isHaveAll: 0,
      }).then((res) => {
        if (res.code === 1) {
          this.treeData2 = res.data;
          // this.typeList = this.handleArr(res.data);
        }
      });
    },
    handleHeight() {
      if (parseInt(this.offsetHeight) <= 62) {
        this.$message.warning("没有更多条件");
        return;
      }
      this.height = this.height === "55px" ? this.offsetHeight : "55px";
    },
    // 获取品牌
    getBandList() {
      post({
        url: "/store/common.Select/getBandList",
      }).then((res) => {
        if (res.code === 1) {
          this.brandList = res.data;
        }
      });
    },
    // 获取材质成色
    getMaterialList() {
      post({
        url: "/store/common.Select/getMaterialList",
      }).then((res) => {
        if (res.code === 1) {
          this.materialList = res.data;
        }
      });
    },
    /*
     *  获取仓库数据
     */
    getWarehouseList() {
      getWarehouseList().then((res) => {
        if (res.code === 1) {
          this.warehouseList = res.data;
        }
      });
    },
    // 获取款式
    getPatternList() {
      post({
        url: "/store/common.Select/getPatternList",
      }).then((res) => {
        if (res.code === 1) {
          this.patternList = res.data;
        }
      });
    },
    // 门店列表
    getMerchantList() {
      getMerchantList({
        page: 1,
        page_num: 999,
      }).then((res) => {
        if (res.code === 1) {
          this.merchantList = res.data;
        }
      });
    },
    getGoods() {
      this.loading = true;
      if (this.type && this.type === 2) {
        getAllGoodsListNew({
          ...this.formInline,
          allot_state: 1,
          goods_tag_id: this.goods_tag_id,
          search_value_type: "itemValue",
          hierarchy_id:
            this.hierarcyhList.length !== 0
              ? this.hierarcyhList.map((item) => {
                  return item.hierarchy_id;
                })
              : [],
        }).then((res) => {
          if (res.code === 1) {
            this.shopList = res.data.list;
            //   .map((item) => {
            //     this.goods_id.forEach((arr) => {
            //       if (arr.goods_id) {
            //         if (item.goods_id === arr.goods_id) {
            //           item.disabled = true;
            //         } else {
            //           item.disabled = false;
            //         }
            //       }
            //     });
            //     return item;
            //   });
            this.total = res.data.total;
            this.loading = false;
          }
        });
      } else {
        goodsReq({
          ...this.formInline,
          allot_state: 1,
          goods_tag_id: this.goods_tag_id,
          search_value_type: "itemValue",
        }).then((res) => {
          if (res.code === 1) {
            this.shopList = res.data.list;
            //   .map((item) => {
            //     this.goods_id.forEach((arr) => {
            //       if (arr.goods_id) {
            //         if (item.goods_id === arr.goods_id) {
            //           item.disabled = true;
            //         } else {
            //           item.disabled = false;
            //         }
            //       }
            //     });
            //     return item;
            //   });
            this.total = res.data.total;
            this.loading = false;
          }
        });
      }
    },
    searchData() {
      const formInline = { ...this.formInline };
      formInline.page = 1;
      this.formInline = formInline;
      this.getGoods();
    },
    handleReset() {
      this.formInline = {
        104: [],
        sale_price: [],
        page: 1,
        page_num: 15,
      };
      const formInline = { ...this.formInline };
      // formInline.page = 1;
      this.formInline = formInline;
      this.getGoods();
    },
    handleCurrentChange(page) {
      const formInline = { ...this.formInline };
      formInline.page = page;
      this.formInline = formInline;
      this.getGoods();
    },
    /*
     *  选择此商品
     */
    handleCurrentShop(row) {
      this.$emit("getGoods", row);
    },
  },
};
</script>

<style lang="less" scoped>
.wrapper {
  transition: all 0.3s;
  overflow: hidden;
  flex: 1;
  .middle {
    display: flex;
    overflow: hidden;
  }
}
.flex-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.title {
  line-height: 60px;
  font-weight: bold;
  font-size: 16px;
  color: rgb(80, 80, 80);
}
</style>